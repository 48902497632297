import { IS_PRODUCTION } from '@/constants'

export const dispatchNewRelicPageAction = (actionName, actionArgs = {}) => {
  const _dispatchEvent = () => {
    if (!IS_PRODUCTION) {
      console.info(
        '%c@analytics/new-relic - dispatch event',
        'color: #1ce783',
        {
          actionName,
          actionArgs
        }
      )
    }

    window?.newrelic?.addPageAction(actionName, actionArgs)
  }

  if (window.newrelic) {
    _dispatchEvent()
    return
  }

  const triggerTrack = setInterval(() => {
    if (!window.tagular) return
    clearInterval(triggerTrack)
    _dispatchEvent()
  }, 150)
}

export const handleNewRelicReady = () => {
  const _newRelicReadyInterval = setInterval(() => {
    if (!window.newrelic) return

    clearInterval(_newRelicReadyInterval)

    if (!IS_PRODUCTION) {
      console.info(`%c@analytics/new-relic - ready`, 'color: #1ce783')
    }
  }, 150)
}
