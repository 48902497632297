'use client'

/* eslint-disable no-undef */
import Script from 'next/script'
import { Open_Sans } from 'next/font/google'
import { GoogleTagManager, GoogleAnalytics } from '@next/third-parties/google'

import { Lab } from 'rvbr-www/red-testing'

import { handleNewRelicReady } from '@/utils/newrelic'

import { ProposalDataProvider } from '@/context/ProposalContext'

import { RED_TESTING_CONFIG } from '@/constants/redtesting-config'

import '@/styles/index.scss'

const OPEN_SANS = Open_Sans({
  weight: ['400', '600', '700'],
  subsets: ['latin'],
  display: 'swap'
})
const isOrganic = process.env.NEXT_PUBLIC_BUILD_TYPE === 'organic'

export default function RootLayout({ children }) {
  return (
    <html lang="pt-br" suppressHydrationWarning={true}>
      <head>
        {isOrganic && (
          <meta
            name="google-site-verification"
            content="LM-QEcdOxmdE0eCbuUuisWaG-GScjeU2uPz-rkeHJ_g"
          />
        )}
        <GoogleTagManager gtmId={gtmId} />
        <GoogleAnalytics gaId={ga4Id} />

        <Script
          id="new-relic-snippet"
          strategy="afterInteractive"
          src={`${basePath}js/new-relic.js`}
          onReady={handleNewRelicReady}
          defer
        />

        <Script
          id="recaptcha-snippet"
          strategy="beforeInteractive"
          src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaId}`}
          defer
        />
      </head>

      <body className={OPEN_SANS.className} suppressHydrationWarning={true}>
        <Lab config={RED_TESTING_CONFIG}>
          <ProposalDataProvider>{children}</ProposalDataProvider>
        </Lab>
      </body>
      <Script
        async
        id="cookies-snippet"
        src="https://cdn.privacytools.com.br/public_api/banner/script/bottom/yBaQ1152.js?t=1"
      />

      <link
        rel="stylesheet"
        href="https://cdn.privacytools.com.br/public_api/banner/style/yBaQ1152.css?t=1"
        precedence="default"
      />
    </html>
  )
}
